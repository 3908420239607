import React from "react";
import {Link} from "gatsby";
import PropTypes from "prop-types";
import Img from "gatsby-image";
// import Img from "gatsby-image";

const SectionIntroPost = ({title, fluid, fields, excerpt}) => {
    return (
        <div className="home__section home__section--info">
            <div className="section__container">
                <div className="info__header">
                    <div className="section__text section__text--info"><h3><strong>{title}</strong><br/>
                        <strong>{title}</strong></h3>
                        <p
                            dangerouslySetInnerHTML={{__html: excerpt}}
                        />

                    </div>
                    <div className="info__link"><Link to={fields.slug} className="input input--button input--link">Info e
                        orari</Link></div>
                </div>

                <div className="info__slider">
                    <div className="tns-outer" id="tns2-ow">
                        <div id="tns2-mw" className="tns-ovh">
                            <div className="tns-inner" id="tns2-iw">
                                <div
                                    className="home__slider home__slider--info  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal"
                                    id="tns2">
                                    <div className="tns-item tns-slide-active" id="tns2-item0">
                                        <div className="responsive_placeholder responsive_placeholder--loaded"></div>
                                        <Img fluid={fluid} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    );
};

SectionIntroPost.defaultProps = {
    type: `calendar`,
    title: ``,
    path: ``,
    author: ``,
    date: ``,
    fluid: ``,
};

SectionIntroPost.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    // path: PropTypes.string,
    author: PropTypes.string,
    date: PropTypes.string,
    fluid: PropTypes.any,
    fields: PropTypes.any,
    excerpt: PropTypes.string,
};

export default SectionIntroPost