import React from "react";
import {Carousel} from "react-responsive-carousel";

const content = [
    {
        title: 'Title 1',
        description: 'Description',
        tease: 'by HR',
        image: '/images/slides/slide-1.jpg',
        link: '/article-1'
    },
    {
        title: 'Title 2',
        description: 'Description',
        tease: 'by HR',
        image: '/images/slides/slide-2.png',
        link: '/article-1'
    },
];


const HomeCarousel = () => (
    <div className="home__section home__section--feature">
        <div className="feature__bar"></div>
        <div className="section__content section__content--feature">
            <div className="home__slider home__slider--feature">
                <Carousel autoPlay showArrows={false} showStatus={false} showThumbs={false} infiniteLoop={true}>
                    {content.map((item, index) => (
                        <article key={index} className="tease tease--feature" style={{
                            ...{background: `url('${item.image}') no-repeat center center `},
                            ...{backgroundSize: `cover`}
                        }}>
                            <a href={item.link} className="article__link">

                                <div className="article__cover article__cover--tease alignment--top" title={item.title}>
                                </div>

                                <div className="article__header article__header--tease">
                                    <h2 className="article__title article__title--tease">{item.title}</h2>
                                    <h3 className="article__subtitle article__subtitle--tease">{item.description}</h3>
                                    <div className="article__info article__info--tease">
                                        <h4>{item.tease}</h4>
                                    </div>
                                </div>
                            </a>
                        </article>
                    ))}
                </Carousel>
            </div>
        </div>
    </div>
);

export default HomeCarousel