import React from "react";
import {Link} from "gatsby";
import PropTypes from "prop-types";
import Img from 'gatsby-image';

const SectionPost = ({type, title, fields, author, date, fluid }) => {
    return(
        <article className={"tease tease--"+type}>
            <Link to={fields.slug} className="article__link">
                <div className="article__cover article__cover--tease">
                    <Img fluid={fluid} />
                </div>
                <div className="article__header article__header--tease">
                    <h2 className="article__title article__title--tease">{title}</h2>
                    <div className="article__info article__info--tease">
                        <h3>{author}</h3>
                        <h4>{date}</h4>
                    </div>
                </div>
            </Link>
        </article>
    );
};

SectionPost.defaultProps = {
    type: `calendar`,
    title: ``,
    path: ``,
    author: ``,
    date: ``,
    fluid: ``,
};

SectionPost.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    // path: PropTypes.string,
    author: PropTypes.string,
    date: PropTypes.string,
    fluid: PropTypes.any,
    fields: PropTypes.any,
};

export default SectionPost