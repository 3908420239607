import React from "react";
import {graphql, StaticQuery} from "gatsby";
import SectionPost from "./sectionPost";

const Section2Col = () => (
    <div className="home__section home__section--mostre">
        <h3 className="section__title section__title--mostre"><a href="/">Mostre</a></h3>
        <div className="section__content section__content--mostre">
            <StaticQuery
                query={sectionQuery}
                render={data => {
                    return (
                        <div className="home__slider home__slider--mostre">
                            {data.allMarkdownRemark.edges.map(({node}) => (
                                <SectionPost
                                    type={"mostre"}
                                    key={node.id}
                                    title={node.frontmatter.title}
                                    path={node.frontmatter.path}
                                    author={node.frontmatter.author}
                                    date={node.frontmatter.date}
                                    fluid={node.frontmatter.image.childImageSharp.fluid}
                                    fields={node.fields}
                                />
                            ))}
                        </div>
                    )
                }}/>
        </div>
    </div>
);

const sectionQuery = graphql`
    query{
        allMarkdownRemark(
            limit: 2
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {
                fields: { langKey: { regex: "/(en|any)/" } }
            },
        ) {
            edges{
                node{
                    id
                    frontmatter{
                        title
                        date(formatString: "DD/MM/YYYY")
                        #                    path
                        author
                        image{
                            childImageSharp{
                                fluid(maxWidth: 900){
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields{
                        slug,
                        langKey,
                        #                    tagSlugs{
                        #                        tag,
                        #                        link
                        #                    }
                    },
                    excerpt
                    html
                }
            }
        }
    }
`;

export default Section2Col