import React from "react";

const HomeLogos = () => (
    <div className="home__logos">
        <div>
            <div>
                <div className="home__logo logo__campania">
                </div>
            </div>
            <div>
                <div className="home__logo logo__napoli">
                </div>
            </div>
        </div>
    </div>
);

export default HomeLogos