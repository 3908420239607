import React from "react";
import {graphql, StaticQuery} from "gatsby";
import SectionIntroPost from "./sectionIntroPost";

const Section1Col = () => (
    <StaticQuery
        query={sectionQuery}
        render={data => {
            return (
                <>
                    {data.allMarkdownRemark.edges.map(({node}) => (
                        <SectionIntroPost
                            type={"mostre"}
                            key={node.id}
                            fields={node.fields}
                            title={node.frontmatter.title}
                            // path={node.frontmatter.path}
                            // author={node.frontmatter.author}
                            // date={node.frontmatter.date}
                            fluid={node.frontmatter.image.childImageSharp.fluid}
                            excerpt={node.excerpt}
                        />
                    ))}
                </>
            )
        }}/>
);

const sectionQuery = graphql`
    query{
        allMarkdownRemark(
            limit: 2
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {
                fields: { langKey: { regex: "/(en|any)/" } }
            },
        ) {
            edges{
                node{
                    id
                    frontmatter{
                        title
                        date(formatString: "DD/MM/YYYY")
                        #                    path
                        author
                        image{
                            childImageSharp{
                                fluid(maxWidth: 900){
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields{
                        slug,
                        langKey,
                        #                    tagSlugs{
                        #                        tag,
                        #                        link
                        #                    }
                    },
                    excerpt
                    html
                }
            }
        }
    }
`;

export default Section1Col